import React, { useState, useEffect } from "react";
import { useLanguage } from 'Context/LanguageContext';

const BytesToImage = ({ imageBytes, blurValue }) => {

  const lan = useLanguage();
  
  return (
    <div>
      {imageBytes ? (
        <img src={imageBytes} style={{ width: "300px", filter: blurValue }} />
      ) : (
        <p>{lan.thereIsNoImageToShow}</p>
      )}
    </div>
  );
};

export default BytesToImage;