import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Grid, Typography, Link, Box } from '@mui/material';
import { Row, Col } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { recordLoginRequest } from '@core/api/account/account.service';
import { loadingStart, loadingStop } from '@core/store/modules/loading/loading.slice';
import { selectSession, selectRequest, selectUser } from '@core/store/modules/session/sessionSelectors';
import { loginUser, setResetSelectRequest } from '@core/store/modules/session/sessionSlice';
import { useLanguage } from 'Context/LanguageContext';
import FielderButton from 'FielderComponents/FielderButton';
import { useFielderSnackBar } from '~/FielderElements/useFielderSnackBar';
import { getLoginAccountRedirect, getErrorMessage } from '../LoginPage.utils';
import { MFA_REQUIRED, MFA_ENABLED } from '@core/constants/errorCodes.constants';
import LoginCard from './LoginCard';
import { FielderFormFieldLogin } from '~/FielderElements/FielderElements.component';
import { FIELDER } from '@core/constants/base.constants';
import Checkbox from '~/Checkbox/Checkbox.component';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

const Login = ({ setShowExternalMessage, setActivateMultiFactorAuthentication }) => {
	const navigate = useNavigate();
	const location = useLocation();
	const dispatch = useDispatch();
	const user = useSelector(selectUser);
	const session = useSelector(selectSession);
	const { isLoading, isSuccess, isError, error } = useSelector(selectRequest);
	const fielderMsg = useFielderSnackBar();
	const lan = useLanguage();
	const fielderId = FIELDER.idElement;
	const [mfaAuthentication, setMfaAuthentication] = useState(false);
	const [showMfaCodeInput, setShowMfaCodeInput] = useState(false);
	const [tokkenSession, setTokkenSession] = useState(null);

	const {
		control,
		handleSubmit,
		formState: { errors },
	} = useForm();

	useEffect(() => {

		if (isSuccess && user && user.acceptPrivacyPolicy === true && user.showMfaAuthentication === false) {
			recordLoginRequest();
			const linkToRedirect = getLoginAccountRedirect(user, location);
			let welcomeMsg = `${lan.welcome} ${user.name || ''} ${user.lastName || ''}`;
			fielderMsg({ message: welcomeMsg, variant: 'success', closeButton: true, duration: 6000 });
			navigate(linkToRedirect);
		}

		if(isSuccess && user && user.showMfaAuthentication === true) {
			setActivateMultiFactorAuthentication({showMfaAuthentication: true, session});
		}

		if (isSuccess && user && user.acceptPrivacyPolicy === false && user.showMfaAuthentication === false) {
			setShowExternalMessage({acceptPrivacyPolicy: true,session});
		}

		if (isError) {
			if (error) {
				
				fielderMsg({ message: getErrorMessage(error.status, lan), variant: 'error',	closeButton: true, duration: 6000});
			
				if(error.status === MFA_REQUIRED) {
					setTokkenSession(error.session);
					setShowMfaCodeInput(true);
				}
			}
			dispatch(setResetSelectRequest());
		}
	}, [isSuccess, isError, error]);

	useEffect(() => {
		if (isLoading) {
			dispatch(loadingStart({ name: 'LOGIN' }));
		} else {
			dispatch(loadingStop('LOGIN'));
		}
	}, [isLoading]);
	
	const handleLogin = async (data) => {
		const formData = { ...data, mfaAuthentication, session: tokkenSession };
		dispatch(loginUser(formData));
	};

	return (
		<LoginCard show={!isLoading}>
			<form onSubmit={handleSubmit(handleLogin)}>
				<Grid item xs={8} className='mx-auto'>
					<FielderFormFieldLogin
						name='username'
						label={lan.user}
						id={fielderId.txt.username}
						control={control}
						rules={{ required: `${lan.user} ${lan.isRequired}` }}
						error={errors.username}
						className='my-3'
						
					/>
					<FielderFormFieldLogin
						name='password'
						label={lan.password}
						id={fielderId.txt.password}
						type='password'
						control={control}
						rules={{
							required: `${lan.password} ${lan.isRequired}`,
							minLength: { value: 3, message: lan.minimunCharacters.replace('{0}', 3) },
						}}
						error={errors.password}
						className='my-3'
					/>
					{showMfaCodeInput && (
						<FielderFormFieldLogin
							name='mfaCode'
							label={lan.mfaCode}
							id={fielderId.txt.mfaCode}
							control={control}
							rules={{ required: `${lan.mfaCode} ${lan.isRequired}` }}
							error={errors.mfaCode}
							className='my-3'
						/>
					)}
				</Grid>
				
				<Grid item className="">
					<Row className="d-flex justify-content-center">
						<Checkbox
							label={lan.activateMultiFactorAuthentication}
							checked={mfaAuthentication}
							onChange={(e) => { setMfaAuthentication(!mfaAuthentication)}}
						/>
						<Link  
							className='mt-2 pt-1'
							href="https://www.appfielder.com/help-mfa/" 
      						underline="hover" 
      						target="_blank" 
     						rel="noopener noreferrer" 
						>
							<Col>
								<Box display="flex" alignItems="center" justifyContent="center">
									<Typography style={{fontSize:'12px', color: '#ED176B'}} align="center">{lan.moreInformation}</Typography>
									<OpenInNewIcon style={{  fontSize:'12px', color: '#ED176B', marginLeft: 4 }} />
								</Box>
							</Col>
						</Link>
					</Row>
				</Grid>

				<Grid item xs={8} className='mx-auto' sx={{ width: '50%', p: 2 }}>
					<FielderButton id='login' type='submit'>
						{lan.login_button}
					</FielderButton>
				</Grid>
			</form>
		</LoginCard>
	);
};

export default Login;
