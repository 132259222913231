import React, { Component, useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import { getMfaAuthentication, getMfaQr } from 'FielderUtils/session/Session';
import TextField from '@mui/material/TextField';
import CardContent from '@mui/material/CardContent';

import BytesToImage from './BytesToImage';
import Typography from '@mui/material/Typography';
import { useLanguage } from 'Context/LanguageContext';
import LooksOneIcon from '@mui/icons-material/LooksOne';
import LooksTwoIcon from '@mui/icons-material/LooksTwo';
import Looks3Icon from '@mui/icons-material/Looks3';

const MfaCardContainer = ({handleEnterMfaCode}) => {
    const lan = useLanguage();
    const regex = /^\d{0,6}$/;
    const [openTextCode, setOpenTextCode] = useState(false);
    const [blurValue, setBlurValue] = useState("blur(5px)");
    const [blurBoolean, setBlurBoolean] = useState(true);
    const [value, setValue] = useState('');

    const handleClickOpenTextCode = () => {
        setOpenTextCode(!openTextCode);
    }

    const handleBlur = (value) => {
        value === true ?  setBlurValue("blur(5px)") : setBlurValue("blur(0px)")
        setBlurBoolean(value);  
    }

    const validateInput = (value) => {
        if (regex.test(value)) {
            setValue(value);
        }
    }
 
    const card = (
        <React.Fragment>
            <CardContent>
                <div className='row d-flex justify-content-start mx-1'>
                    <Typography gutterBottom sx={{  fontSize: 20 }}>
                        {lan.authenticatorApp}
                    </Typography>
                </div>
                <div className='row d-flex justify-content-start mx-1'>
                    <Typography sx={{  color: 'text.secondary', fontSize: 14, textAlign:'justify' }}>
                        {lan.anAuthenticatorIsAmeans}
                    </Typography>
                </div>
                <div className='row d-flex justify-content-start mx-1 mt-1'>
                    <div className='col-auto no-padding'>
                        <LooksOneIcon sx={{ color: '#ED176B' }} />
                    </div>
                    <div className='col'>
                        <Typography sx={{  color: 'text.secondary', fontSize: 11, textAlign:'justify' }}>
                            {lan.mfaTextIntro1}
                        </Typography>
                    </div>
                </div>
                <div className='row d-flex justify-content-start mx-1 mt-1'>
                    <div className='col-auto no-padding'>
                        <LooksTwoIcon sx={{ color: '#ED176B' }} />
                    </div>
                    <div className='col'>
                        <Typography sx={{  color: 'text.secondary', fontSize: 11, textAlign:'justify' }}>
                            {lan.mfaTextIntro2}
                        </Typography>
                        <a style={{ cursor:'pointer'}} onClick={handleClickOpenTextCode}>
                            <Typography sx={{  color: 'blue', fontSize: 11, textAlign:'justify' }}>
                                {lan.mfaTextIntro3}
                            </Typography>
                        </a>
                    </div>
                </div>
                {openTextCode && ( 
                    <div className='row d-flex justify-content-start mx-1 mt-1'>
                        <div className='col no-padding'>
                            <Typography sx={{  color: 'text.secondary', fontSize: 11, fontWeight: 900, textAlign:'justify' }}>
                                {getMfaAuthentication()}
                            </Typography>
                        </div>
                    </div>
                )}

                <div className='row d-flex justify-content-start mx-1 mt-1'>
                    <div className='col mx-5'>
                        <BytesToImage imageBytes={getMfaQr()} blurValue={blurValue}/>
                    </div>
                </div>
                <div className='row d-flex justify-content-center mx-1 mt-1'>
                    <a style={{ cursor:'pointer'}} onClick={() => handleBlur(!blurBoolean)}>
                        <Typography sx={{  color: 'blue', fontSize: 11, textAlign:'justify' }}>
                            {lan.mfaTextIntro4}
                        </Typography>
                    </a>
                </div>
                <div className='row d-flex justify-content-start mx-1 mt-1'>
                    <div className='col-auto no-padding'>
                        <Looks3Icon sx={{ color: '#ED176B' }} />
                    </div>
                    <div className='col'>
                        <Typography sx={{  color: 'text.secondary', fontSize: 11, textAlign:'justify' }}>
                                    {lan.mfaTextIntro5}
                        </Typography>
                    </div>
                </div>
                <div className='row d-flex justify-content-center mx-1 mt-1'>    
                    <div className='col mt-1'>
                        <TextField
                            label={lan.mfaCode}
                            id="outlined-size-small"
                            size="small"
                            value={value}
                            onChange={event => validateInput(event.target.value)}
                        />
                    </div>
                    <div className='col d-flex justify-content-center'>
							<button
								id='ErrorMessage-btn'
								type='submit'
								className='btn btn-outline-primary btn-success error-message-btn-margin'
								onClick={() => handleEnterMfaCode(value)}
							>
								{lan.enableMfa}
							</button>
						</div>
				</div>
             
            </CardContent>
        </React.Fragment>
    );

    
    return (
        <Box className={'px-2'}>
            <Card sx={{ border: '2px solid #808080',}} variant="outlined">{card}</Card>
        </Box>
    );
    
}

export default MfaCardContainer;