import axios from 'axios';
import API_LINKS from '../../../api/apiGateWayLinks';
import { handlePostRequest } from '@core/api/request'

// Function to orchestrate the upload process
const presignedUrlForUpload = async (file_path, file_name, file, options, clientUserId, onProgress) => {
    try {
      const presignedUrlAndFields = await getPresignedUrl(`${file_path}/${file_name}`, options, clientUserId);
      await uploadToPresignedUrl(presignedUrlAndFields, file, onProgress);
      const url = process.env.FIELDER_CONTENT_LINK + '/' + file_path + '/' + file_name;
     
      return url;
    } catch (error) {
      // Handle error
      console.error("Error uploading file:", error);
      return null;
    }
};

//options shape: { contentType: file.type, acl: 'public-read' }
const getPresignedUrl = async (file_key, options, idClientUser) => {

  const requestBody = {
    key: file_key,
    idClientUser: idClientUser,
    ...options,
  };

  const onSuccess = (res) => res.pregsinedFields;
  const presignedUrlAndFields = await handlePostRequest(API_LINKS.presignedUrlGenerator, requestBody, onSuccess, null, null);
  return presignedUrlAndFields;
};

// Function to upload the selected file using the generated presigned url
const uploadToPresignedUrl = async (presignedUrlAndFields, selectedFile, onProgress) => {

  const url = presignedUrlAndFields.url;
  const formData = new FormData();
  Object.entries(presignedUrlAndFields.fields).forEach(([key, value]) => {
    formData.append(key, value);
  });
  formData.append("file", selectedFile);

  // Upload file to pre-signed URL
  const uploadResponse = await axios.post(url, formData, {
    onUploadProgress: (progressEvent) => {
      const percentCompleted = Math.round(
        (progressEvent.loaded * 100) / progressEvent.total
      );
      if(onProgress){
        onProgress(percentCompleted);
      }
      console.log(`Upload Progress: ${percentCompleted}%`);
    },
  });
  console.log(uploadResponse);
};

export default presignedUrlForUpload;