import React, { Component } from 'react';

import LinkIcon from '@assets/icons/link-icon.svg';
import redAlertIcon from '@assets/icons/redAlert-icon.svg';
import { getAccountsByFilterAsyncSelect } from 'FielderUtils/account/account';
import { getIdToken } from 'FielderUtils/session/Session';
import { Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';

import AsyncSearch from 'OldComponents/AsyncSearch/AsyncSearch';
import Popup from '../../Popup';

import { addDomainAccountRequest } from '@core/api/masterUser/masterUser.service';

class AddDomain extends Component {
	constructor(props) {
		super(props);

		const session = this.props.session;

		this.state = {
			idTeamSelected: null,
			//Session
			idUser: session.user ? session.user.idUser : 0,
			idAccount: session.user ? session.user.idAccount : 0,
			role: session.user.role,
			userName: session.user.userName,

			globalAccountInfo: {
				domain: '',
			},
			idAccountSelected: null,
			idUserSelected: null,
		};
	}

	validateDomain = (domain) => {
		if (domain !== '') {
			document.getElementById('domain-name-alert-icon').style.display = 'none';
			document.getElementById('domain-name-red-input').style.borderColor = '#ced4da';
			return true;
		}
		document.getElementById('domain-name-alert-icon').style.display = 'inline';
		document.getElementById('domain-name-red-input').style.borderColor = '#c70101';
		return false;
	};

	assignAccount = () => {
		var isValidDomain = this.validateDomain(this.state.globalAccountInfo.domain);

		if (this.state.idAccountSelected !== null && this.state.idUserSelected !== null) {
			document.getElementById('account-alert-icon').style.display = 'none';
		} else {
			document.getElementById('account-alert-icon').style.display = 'inline';
		}

		if (
			this.state.idAccountSelected !== null &&
			this.state.idUserSelected !== null &&
			isValidDomain
		) {
			document.getElementById('register-form-hint').style.display = 'none';
			document.getElementById('account-alert-icon').style.display = 'none';

			const body = {
				idAccount: this.state.idAccountSelected,
				accountDomain: this.state.globalAccountInfo.domain,
				idUser: this.state.idUserSelected,
			};


			const onSuccess = (response) => {
				this.props.closeModalSuccess();
			}

			const onError = (error) => {
				this.props.errorMessageCreation();
			}

			const loadingConfig = { dispatch: this.props.dispatch }
			addDomainAccountRequest(body, onSuccess, onError, loadingConfig)

		} else {
			document.getElementById('register-form-hint').style.display = 'inline';
		}
	};

	setAccount = (event) => {
		this.setState({
			idAccountSelected: event.value.id,
			idUserSelected: event.value.idUserClient,
		});
	};

	getAccountsList = async (event) => {
		if (event.length >= 1) {
			var accountsListArray = await getAccountsByFilterAsyncSelect(
				event,
				this.state.role,
				this.state.userName,
				getIdToken()
			);
			this.setState({ accountsListArray: accountsListArray });
			return accountsListArray;
		}
	};

	setFieldValue(value, field) {
		if (field === 'domain') {
			this.setState((prevState) => ({
				globalAccountInfo: {
					// object that we want to update
					...prevState.globalAccountInfo, // keep all other key-value pairs
					domain: value, // update the value of specific key
				},
			}));
		}
	}

	render() {
		return (
			<Popup
				title={this.props.lan.assignDomain}
				icon='domain-icon.svg'
				show
				closeButton
				onClose={this.props.closeDefaultModal}
				buttonText={this.props.lan.assign}
				buttonOnClick={this.assignAccount}
			>
				<Row className='Fielder-input-border'>
					<Col sm={'auto'} className='mt-2'>
						<img src={LinkIcon} className='icon mr-auto' alt='' />
					</Col>
					<Col>
						<AsyncSearch
							className='react-select__control full-width mt-1 mb-3'
							getOptions={(e) => this.getAccountsList(e)}
							onChange={(e) => this.setAccount(e)}
							label={this.props.lan.search}
							lan={this.props.lan}
							id='select-add-client'
						/>
						<img
							src={redAlertIcon}
							alt=''
							id='account-alert-icon'
							className='GlobalUser-dir-desc-fieldContainer-icon'
						></img>
					</Col>
				</Row>

				<Row>
					<div className='col-sm-12 mt-3 Fielder-cards-txt-label-input'>
						{this.props.lan.domain}
					</div>
					<div className='col-sm-12 mr-3 pr-4 font-inputs'>
						<div className='input-group input-group-sm mb-3'>
							<div className='input-group-prepend'></div>
							<input
								type='text'
								id='domain-name-red-input'
								className='form-control Fielder-cards-font-inputs'
								aria-label='Small'
								aria-describedby='inputGroup-sizing-sm'
								value={this.state.globalAccountInfo.domain}
								onChange={(e) => this.setFieldValue(e.target.value, 'domain')}
							/>
						</div>
						<img
							src={redAlertIcon}
							alt=''
							id='domain-name-alert-icon'
							className='Domain-dir-desc-fieldContainer-icon'
						></img>
					</div>
				</Row>

				<Row className='d-flex justify-content-center'>
					<div id='register-form-hint' className='Register-form-hint mt-3'>
						<p>*{this.props.lan.requiredFields}</p>
					</div>
				</Row>
			</Popup>
		);
	}
}

const mapStateToProps = (state) => ({
	session: state.utils.session,
});
export default connect(mapStateToProps)(AddDomain);
