import API_LINKS from 'FielderAPILinks';
import { getIdToken } from 'FielderUtils/session/Session';
/**
 *
 * @param {string} filter       - Filter to search the teams by
 * @param {number} idAccount    - Id of the account in use
 * @param {number} idUser       - Id of the current user
 * @param {string} idToken      - Login token
 *
 * @description
 *      Gets all groups by the filter
 * @returns {array} - contains the teams found (returns empty if error)
 */
export const getUsersByFilter = (filter, role, userName, idToken) => {
	let body = {
		requestType: 'GET_USERS_BY_FILTER',
		filter: filter,
		user: {
			role: role,
			userName: userName,
		},
	};

	return fetch(API_LINKS.accountManager, {
		method: 'POST',
		body: JSON.stringify(body),
		headers: {
			'Content-type': 'application/json',
			Authorization: idToken,
		},
	})
		.then((res) => res.json())
		.then((res) => {
			if (res.success === true) {
				return res.users;
			} else {
				console.error(res);
				return [];
			}
		});
};

/**
 *
 * @param {string} filter       - Filter to search the team by
 * @param {number} idAccount    - Id of the account in use
 * @param {number} idUser       - Id of the current user
 * @param {string} idToken      - Login token
 *
 * @description
 *  Gets all teams by the filter send and sets the information and format for the asyncSelect component
 */
export const getUserByFilterAsyncSelect = async (filter, role, userName) => {

	let users = await getUsersByFilter(filter, role, userName, getIdToken());
	let usersList = [];

	users.forEach((user) => {
		usersList.push({
			label:
				user.idUser +
				' ' +
				user.name +
				' '+
				user.lastName +
				' (' +
				user.userName + ' '+
				') ',
			value: user,
		});
	});

	return usersList;
};
