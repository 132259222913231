import React, { useEffect, useState } from 'react';

import UserIcon from '@assets/icons/add-client-icon.svg';
import { getUserByFilterAsyncSelect } from 'FielderUtils/user/user';
import { Row, Col } from 'react-bootstrap';
import { useLanguage } from 'Context/LanguageContext';
import { useSelector, useDispatch } from 'react-redux';

import { selectUser } from '@core/store/modules/session/sessionSelectors';
import AsyncSearch from 'OldComponents/AsyncSearch/AsyncSearch';
import Switch from '~/Switch/Switch.component';

import Popup from '../../Popup';

import { enableDisableMfaRequest } from '@core/api/masterUser/masterUser.service';
import { set } from 'js-cookie';

const EnableDisableMfa = ({closeDefaultModal, closeModalSuccess, errorMessageCreation }) => {

	const lan = useLanguage();

	const [idUser, setIdUser] = useState(null);
	const [disabled, setDisabled] = useState(true);
	const [switchValue, setSwitchValue] = useState(false);
	const [userName, setUserName] = useState(null);
	
	const user = useSelector(selectUser);
	const dispatch = useDispatch();

	const onChangeUser = (e) => {
		console.log(e)
		if(e != null){
			setIdUser(e.value.idUser);
			setUserName(e.value.userName);
			setSwitchValue(e.value.mfaAuthentication);
		} else {
			setIdUser(null);
			setUserName(null);
			setSwitchValue(false);
		}
	}

	const enableDisableMfa = async (e) => {
		console.log(e)
		
		setSwitchValue(e);

		const onSuccess = () => {
		
			closeModalSuccess();
		}

		const onError = (error) => {
			errorMessageCreation(error)
		}

		const loadingConfig = { dispatch }
		const body = {
			idUser: idUser,
			enableMfa: e,
			username: userName
		}
		enableDisableMfaRequest(body, onSuccess, onError, loadingConfig)
	}

	useEffect(() => {
		if (idUser != null) {
			setDisabled(false);
			setSwitchValue
		} else {
			setDisabled(true);
		}
	}
	, [idUser]);



		return (
			<Popup
				title={lan.enableDisableMfa}
				icon='domain-icon.svg'
				show
				closeButton
				onClose={closeDefaultModal}
			>
				<Row className='Fielder-input-border'>
					<Col sm={'auto'} className='mt-2'>
						<img src={UserIcon} className='icon mr-auto' alt='' />
					</Col>
					<Col>
						<AsyncSearch
							className='react-select__control full-width mt-1 mb-3'
							getOptions={(e) => getUserByFilterAsyncSelect(e, user.role, user.userName)}
							onChange={(e) => onChangeUser(e)}
							label={lan.search}
							lan={lan}
							id='select-mfa-user'
						/>
					</Col>
				</Row>

				<Row className='ml-1'>
					<Switch label={lan.enableDisableMfa} disabled={disabled} value={switchValue} onChange={(e) =>enableDisableMfa(e)} />
				</Row>
			</Popup>
		);
	
}


export default EnableDisableMfa;
