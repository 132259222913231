import React, { useState, useEffect } from 'react';

import { Grid, MenuItem, Select } from '@mui/material';
import { useLanguage } from 'Context/LanguageContext';
import ResultMessage from 'FielderComponents/ResultMessage';
import { isLocalUser } from 'Utils/account/utils';

import { useChangeAccount } from '../useChangeAccount';

const AccountSelect = ({ user }) => {
	const lan = useLanguage();
	const { success, data, isPending, changeAccountUser, mustAcceptPrivacyPolicy } =
		useChangeAccount();

	const [userOptions, setUserOptions] = useState([]);
	const [show, setShow] = useState(false);
	const [selectedUser, setSelectedUser] = useState(null);
	const [resultMessageInfo, setResultMessageInfo] = useState({
		show: false,
		message: undefined,
		success: undefined,
	});

	useEffect(() => {
		if (success === false) {
			setResultMessageInfo({
				show: true,
				success: false,
				message: data,
			});
		}
	}, [success, data, isPending]);

	useEffect(() => {
		if (user) {
			let localUser = isLocalUser(user);
			setShow(localUser && user.users != null);
			setSelectedUser('' + user.idUser);
		}

		if (user !== undefined && user != null && user.users != null && user.users.length > 0) {
			let options = user.users.map((element) => {
				if (element.account) {
					return (
						<MenuItem value={element.idUser.toString()} key={element.idUser}>
							{element.account.name}
						</MenuItem>
					);
				} else {
					return (
						<MenuItem value={element.idUser.toString()} key={element.idUser}>
							{lan.globalDashboard}
						</MenuItem>
					);
				}
			});

			setUserOptions(options);
		} else {
			//setShow(false);
		}
	}, [user]);

	const changeUser = (event) => {
		let userId = event.target.value;
		let selectedUser = user.users.find((element) => element.idUser == userId);
		changeAccountUser(selectedUser);
	};

	const closeResultMessage = () => {
		setResultMessageInfo({
			show: false,
			message: undefined,
			success: undefined,
		});
	};

	return (
		<>
			{show && (
				<Grid item xs={2}>
					<Select
						variant='outlined'
						margin='dense'
						className='mt-3'
						placeholder={lan.selectPlaceHolder}
						value={selectedUser}
						onChange={changeUser}
						fullWidth
					>
						{userOptions}
					</Select>
					);
				</Grid>
			)}

			{resultMessageInfo.show && (
				<ResultMessage
					onClose={closeResultMessage}
					success={resultMessageInfo.success}
					text={resultMessageInfo.message}
				/>
			)}
		</>
	);
};

export default AccountSelect;
