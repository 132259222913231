/* eslint-disable no-param-reassign */
import { createSlice, Slice, Action, CaseReducer, PayloadAction } from '@reduxjs/toolkit';
import { LoadingConfig, LoadingModules } from '@core/models/loading.types';

export type LoadingState = {
	readonly isLoading: boolean;
	readonly config: LoadingConfig;
	readonly loading: LoadingModules;
};

const defaultConfig: LoadingConfig = {
	icon: null,
	text: null,
	progress: null,
	allowTopBar: false,
};

const initialState: LoadingState = {
	isLoading: false,
	loading: {},
	config: defaultConfig,
};

function isPendingAction(action: Action) {
	return action.type.startsWith('loading') && action.type.endsWith('pending');
}

function isSuccessAction(action: Action) {
	return action.type.startsWith('loading') && action.type.endsWith('fulfilled');
}

function isFailureAction(action: Action) {
	return action.type.startsWith('loading') && action.type.endsWith('rejected');
}

const loadingStartAction: CaseReducer<LoadingState, PayloadAction<{ name: string; config?: LoadingConfig }>> = (
	state,
	action
) => {
	state.loading[action.payload.name] = true;

	if (action.payload.config) {
		state.config = action.payload.config;
	}
};

const loadingStopAction: CaseReducer<LoadingState, PayloadAction<string | { name: string }>> = (state, action) => {
	
	if (!action.payload) {
		return;
	} else if (typeof action.payload === 'string') {
		state.loading[action.payload] = false;
	} else if (typeof action.payload === 'number') {
		state.loading[action.payload] = false;
	} else if ('name' in action.payload) {
		state.loading[action.payload.name] = false;
	}

	const hasLoadingState = Object.keys(state.loading).some((loadingKey) => state.loading[loadingKey] === true);
	
	if (!hasLoadingState) {
		state.config = defaultConfig;
	}
};

const loadingResetAction: CaseReducer<LoadingState> = (state) => {
		Object.keys(state.loading).forEach((key) => {
		  state.loading[key] = false;
		});
		state.config = defaultConfig;
};


const loadingSlice: Slice = createSlice({
	name: 'loading',
	initialState,
	reducers: {
		setLoading: (state, action) => {
			if (typeof action.payload === 'boolean') {
				state.isLoading = action.payload;
				state.config = defaultConfig;
			} else {
				const { isLoading, config } = action.payload;
				state.isLoading = isLoading;

				if (isLoading && config) {
					state.config.icon = config.icon || null;
					state.config.text = config.text || null;
					state.config.progress = config.progress || null;
					state.config.allowTopBar = config.allowTopBar || false;
				} else {
					state.config = defaultConfig;
				}
			}
		},
		loadingStart: loadingStartAction,
		loadingStop: loadingStopAction,
		resetLoading: loadingResetAction,
	}
});

export const { setLoading, loadingStart, loadingStop, resetLoading } = loadingSlice.actions;

export default loadingSlice.reducer;
