import React, { useState } from 'react';

import { Divider, IconButton, List } from '@mui/material';
import { useLanguage } from 'Context/LanguageContext';
import { useSideBarUpdate } from 'Context/SideBarContext';
import ResultMessage from 'FielderComponents/ResultMessage';
import AddGlobalAccount from 'OldComponents/InternalAdminComponents/AddDomain/AddDomain';
import EnableDisableMfa from 'OldComponents/InternalAdminComponents/EnableDisableMfa/EnableDisableMfa';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { useNavigate } from 'react-router-dom';

import { Toolbar } from '../styled';
import {
	AccountReview,
	AddDomainSideMenu,
	AddGlobalUserSideMenu,
	AccountsSideMenu,
	InternalAdminDashboardSideMenu,
	RecordsSideMenu,
	EnableDisableMfaSideMenu,
} from './InternalAdminSideMenuComponents';

const SidebarInternalAdmin = () => {
	const setSideBar = useSideBarUpdate();
	const navigate = useNavigate();
	const lan = useLanguage();

	const [showAddDomain, setShowAddDomain] = useState(false);
	const [showMfaMenu, setShowMfaMenu] = useState(false);
	const [resultModal, setResultModal] = useState({
		show: false,
		message: '',
		success: false,
	});

	const handleDrawerClose = () => setSideBar(false);
	const changeShowAddDomain = () => setShowAddDomain(!showAddDomain);
	const changeShowMfaMenu = () => setShowMfaMenu(!showMfaMenu);

	const showResultModal = (success) => () => {
		if (success) {
			setResultModal({
				success: true,
				message: lan.domainCreatedSuccessfully,
				show: true,
			});
		} else {
			setResultModal({
				success: false,
				message: lan.domainCreatedError,
				show: true,
			});
		}
	};

	const showMfaResultModal = (success) => () => {
		if (success) {
			setResultModal({
				success: true,
				message: lan.userMfaHasBeenDisabled,
				show: true,
			});
			setShowMfaMenu(false);
		} else {
			setResultModal({
				success: false,
				message: lan.theUserDoesNotHaveAnMfaConfiguration,
				show: true,
			});
		}
	};


	const hideResultModal = () => {
		setResultModal({
			success: false,
			message: '',
			show: false,
		});
		changeShowAddDomain();
	};

	return (
		<>
			{showAddDomain && (
				<AddGlobalAccount
					lan={lan}
					closeDefaultModal={changeShowAddDomain}
					closeModalSuccess={showResultModal(true)}
					errorMessageCreation={showResultModal(false)}
				/>
			)}

			{showMfaMenu && (
				<EnableDisableMfa
					closeDefaultModal={changeShowMfaMenu}
					closeModalSuccess={showMfaResultModal(true)}
					errorMessageCreation={showMfaResultModal(false)}
				/>
			)}

			{resultModal.show && (
				<ResultMessage onClose={hideResultModal} success={resultModal.success} text={resultModal.message} />
			)}

			<Toolbar>
				<IconButton onClick={handleDrawerClose}>
					<ChevronLeftIcon />
				</IconButton>
			</Toolbar>

			<Divider />

			<List>
				<InternalAdminDashboardSideMenu goToPage={navigate} />
				<AccountsSideMenu goToPage={navigate} />
				<RecordsSideMenu goToPage={navigate} />
				<AddGlobalUserSideMenu goToPage={navigate} />
				<AddDomainSideMenu showAddDomain={changeShowAddDomain} />
				<EnableDisableMfaSideMenu showMfaMenu={changeShowMfaMenu}/>
				<AccountReview  goToPage={navigate} />
			</List>

			<Divider />
		</>
	);
};

export default SidebarInternalAdmin;
