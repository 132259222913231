import React from 'react';

import { selectIsLoading } from '@core/store/modules/loading/loading.selector';
import { selectAlertMessage } from '@core/store/modules/message/messageSelectors';
import { selectUser } from '@core/store/modules/session/sessionSelectors';
import Loading from 'OldComponents/Loading';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';

import { Content, FielderContainer, ToolbarSpace, useStyles } from './FielderLayout.styles';
import AlertMessage from '~/AlertMessage/AlertMessage.component';
import SideMenu from '~/SideMenu/SideMenu';
import TopMenu from '~/TopMenu/TopMenu.component';
import ChatBotFielder from '~/ChatBotFielder/ChatBotFielder';
import RoleUtils from 'FielderUtils/roles/roleUtils';
import { selectSession } from '@core/store/modules/session/sessionSelectors';

const Layout = () => {
	const session = useSelector(selectSession);
	const user = useSelector(selectUser);
	const alertMessage = useSelector(selectAlertMessage);
	const isLoading = useSelector(selectIsLoading);

	return (
		<FielderContainer>
			{isLoading && <Loading />}
			{alertMessage && <AlertMessage />}
			<TopMenu />
			{user && user.acceptPrivacyPolicy && !user.showMfaAuthentication && <SideMenu />}
			{	
				user && 
				!RoleUtils.isUserClient(user) &&
				(session.accountPreferences.showMayaManagerChat ||
				session.accountPreferences.showMayaTechnicianChat) &&
				<ChatBotFielder />
			}

			<Content container direction='column' alignItems='stretch' pt={4}>
				<ToolbarSpace />
				<React.Suspense fallback={null}>
					<Outlet />
				</React.Suspense>
			</Content>
		</FielderContainer>
	);
};

Layout.prototype = {
	children: PropTypes.node.isRequired,
};

export default Layout;
